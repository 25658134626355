import React, { useCallback, useEffect, useRef, useState } from "react";
import Cookies from "universal-cookie";
import * as axios from "axios";
import { notification } from "antd";
import { Resizable } from "react-resizable";
import { constant } from "lodash";
export * from "./constants";

//generic
//const URL_WITH_VERSION = window.location.protocol+ process.env.REACT_APP_URL + process.env.REACT_APP_VERSION;
//docker
const URL_WITH_VERSION =
  process.env.REACT_APP_URL + process.env.REACT_APP_VERSION;
//console.log("URL is " + URL_WITH_VERSION);
//docker
export const URL_WITHOUT_VERSION = process.env.REACT_APP_URL;
const cookies = new Cookies();
//export const URL_WITHOUT_VERSION = window.location.protocol + process.env.REACT_APP_URL;

// let password = cookies.get('encpass');

// let username = cookies.get('username');

// axios.interceptors.request.use(req => {

//     // `req` is the Axios request config, so you can modify

//     // the `headers`.

//     req.headers.password =password;

//     req.headers.username =username;

//     return req;

//   });

const apiGetCall = (url, headers = undefined, callback = null) => {
  let getHeaders = {};
  if (headers) {
    headers = JSON.stringify(headers);
    getHeaders["CONTENT-TYPE"] = "application/json";
    getHeaders["Access-Control-Allow-Origin"] = "*";
    getHeaders["X-FILTER"] = headers;
  }
  axios({
    method: "GET",
    url: url,
    headers: getHeaders,
  }).then((response) => {
    callback(response.data);
  });
};

const apiPostCall = (
  method,
  url,
  data,
  callback = null,
  postHeaders = null
) => {
  axios({
    method: method,
    url: url,
    data: data,
  })
    .then((response) => {
      if (typeof callback === "function") {
        return callback(response.data);
      } else {
        return response.data;
      }
    })
    .catch((err) => {
      openNotificationWithIcon("error", err.message, 3);
    });
};

export const awaitPostAPICall = async (url, data) => {
  let callback;
  try {
    callback = await axios({ method: "POST", url: url, data: data });
  } catch (err) {
    openNotificationWithIcon("error", err.message, 5);
  }
  return callback ? callback["data"] : {};
};

export const postAPICallFormData = async (url, data) => {
  let callback;
  try {
    callback = await axios({
      method: "POST",
      url,
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (err) {
    openNotificationWithIcon("Error !!", err.message, 5);
  }
  return callback ? callback["data"] : null;
};

export const getAPICall = async (url, headers = "") => {
  let getHeaders = {};
  if (headers) {
    // headers = JSON.stringify(headers)
    getHeaders["headers"] = {
      "CONTENT-TYPE": "application/json",
      crossorigin: "true",
      "X-FILTER": JSON.stringify(headers),
      USERNAME: cookies.get("username"),
      PASSWORD: cookies.get("encpass"),
      "Access-Control-Allow-Origin": "*",
    };
  } else {
    getHeaders["headers"] = {
      "CONTENT-TYPE": "application/json",
      crossorigin: "true",
      USERNAME: cookies.get("username"),
      PASSWORD: cookies.get("encpass"),
    };
  }
  let callback = await axios.get(url, getHeaders);
  return callback["data"];
};

export const postAPICall = async (
  url,
  data,
  method = null,
  callback = null,
  headers = null
) => {
  // console.log(url, data, method, callback, headers, "lll");
  let postHeaders = {
    userId: `${cookies.get("user_id")}`,
    username: `${cookies.get("username")}`,
  };
  if (headers) {
    headers = JSON.stringify(headers)
    let token = headers["auth_token"];
    delete headers["auth_token"]; // remove it
    postHeaders["headers"] = {
      crossorigin: "true",
      auth_token: token,
    };
    postHeaders["Access-Control-Allow-Origin"] = "*";
  }

  if (typeof method === "function") {
    callback = method;
    method = "post";
  }

  // const loggedInUserid = cookies.get("user_id");
  // const loggedInUserName = cookies.get("username");

  // postHeaders["headers"] = {
  //   user_name: loggedInUserName,
  //   user_id: loggedInUserid,
  // };

  // console.log(postHeaders, "gggg", postHeaders);
  return apiPostCall(method, url, data, callback, postHeaders);
};

export const CALL_MASTER_API = (
  method,
  pathUrl,
  postData = null,
  qParams = null,
  headers = null,
  callback = null
) => {
  if (typeof headers === "function") {
    callback = headers;
    headers = undefined;
  }
  switch (method) {
    case "get":
      let _url = `${URL_WITH_VERSION}/master/list?t=${pathUrl}`;
      if (qParams) {
        qParams = objectToQueryString(qParams);
        _url += `&${qParams}`;
      }
      return apiGetCall(_url, headers, callback);
    case "post":
    case "put":
      return apiPostCall(
        method,
        `${URL_WITH_VERSION}/master/${
          method === "put" ? "update" : "save"
        }?t=${pathUrl}`,
        postData,
        callback
      );
    case "delete":
      return apiPostCall(
        method,
        `${URL_WITH_VERSION}/master/delete?t=${pathUrl}`,
        postData,
        callback
      );
    default:
      return null;
  }
};

function objectToQueryString(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

export const hasErrors = (fieldsError) => {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
};

export const objectToQueryStringFunc = objectToQueryString;

export const GET_STATUS_BADGE = (status) => {
  switch (status) {
    case 1:
      return "#52c41a";
    case 0:
      return "#6c757d";
    case 3:
      return "#f5222d";
    default:
      return null;
  }
};

export const GET_STATUS_TEXT = (status) => {
  switch (status) {
    case 1:
      return "Active";
    case 0:
      return "Inactive";
    case 3:
      return "Deleted";
    default:
      return null;
  }
};

export const apiDeleteCall = (url, data, callback = null) => {
  axios({
    method: "DELETE",
    url: url,
    data: data,
  }).then((response) => {
    if (typeof callback === "function") {
      return callback(response.data);
    } else {
      return response.data;
    }
  });
};

// type = success | info | warning | error
export const openNotificationWithIcon = (type, msg, duration = 5) => {
  let upperCaseTitle = (
    <h4 className={"notify-" + type}>
      {type.charAt(0).toUpperCase() + type.slice(1)}
    </h4>
  );

  notification.config({
    getContainer() {
      return document.body;
    },
  });
  notification.destroy();

  notification[type]({
    message: upperCaseTitle,
    description: msg,
    duration: duration,
  });
};

export const ResizeableTitle = (props) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export const sanitize = (object) => {
  Object.entries(object).forEach(([k, v]) => {
    if (v && typeof v === "object" && !v.hasOwnProperty("_isAMomentObject"))
      sanitize(v);
    if (
      (v && typeof v === "object" && !Object.keys(v).length) ||
      v === null ||
      v === undefined ||
      v.length === 0
    ) {
      if (Array.isArray(object)) object.splice(k, 1);
      else if (!(v instanceof Date)) delete object[k];
    }
  });
  return object;
};

export const ValidateDisplayValue = (val) => {
  return isFinite(val) ? val : "N/A";
};

export const randomFixedInteger = function(length) {
  return Math.floor(
    Math.pow(10, length - 1) +
      Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1)
  );
};

export const isValidEmail = (email) => {
  const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return !(!email || regex.test(email) === false);
};

export const getBunkerspotprice = async (value = null) => {
  try {
    const data = await fetch(
      `${URL_WITH_VERSION}/port-bunker-activity/spotprice`
    );
    const respdata = await data.json();
    if (respdata.prices) {
      //dispatch({ type: "INITIAL_LOAD_DATA", payload: respdata.prices });
      value = respdata.prices;
    }
  } catch (err) {
    console.log(err);
    openNotificationWithIcon("error", "Something went wrong", 3);
  }
  return value;
};

export const getVesselParticulars = async (vesselId) => {
  const url = `${process.env.REACT_APP_VESSEL_NONAIS}/${vesselId}?apikey=${
    process.env.REACT_APP_VESSEL_API_KEY
  }`;

  return await fetch(url, {
    method: "GET",
    headers: {
      "access-control-allow-origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export default URL_WITH_VERSION;

export const IMAGE_PATH = process.env.REACT_APP_IMAGE_PATH;

// custom hoook for calling a callback just after the setstate
export const useStateCallback = (initialState) => {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null); // init mutable ref container for callbacks
  const setStateCallback = useCallback((state, cb) => {
    cbRef.current = cb; // store current, passed callback in ref
    setState(state);
  }, []); // keep object reference stable, exactly like `useState`
  useEffect(() => {
    // cb.current is `null` on initial render,
    // so we only invoke callback on state *updates*
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null; // reset callback after execution
    }
  }, [state]);
  return [state, setStateCallback];
};
